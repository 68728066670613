import { splitProps, type ParentProps } from 'solid-js';
import type { CollectResponseDto, FlipResponseDto } from '~/modules/api';
import { FavoriteAction, LikeAction, ShareAction } from '../actions';

// More actions will be added when other top navigation components are merged
export type TopNavAction = 'open-menu';

export type TopNavProps = {
  creatorName?: string;
  content: FlipResponseDto;
  class?: string;
  style?: string;
  collect?: CollectResponseDto;
  likeReactionId?: string;
};

export function FlipTopNav(props: ParentProps<TopNavProps>) {
  const [, rest] = splitProps(props, ['creatorName', 'class']);

  return (
    <div
      data-component="TopNav"
      class={`flex justify-between items-center w-full font-body ${props.class}`}
      {...rest}
    >
      {/* <Button
        id="BackButton"
        variant="link"
        size="icon"
        class="w-8 h-11 text-clip mr-2 md:mr-4"
        onclick={() => window.history.back()}
      >
        <ChevronLeftIcon class="size-10" />
      </Button> */}

      <div data-part="page-info" class="flex-row grow">
        {props.children}
      </div>

      <div class="flex items-center gap-5">
        <LikeAction
          reactionId={props.likeReactionId}
          variant="link"
          size="icon"
          class="size-6"
          target={props.content}
        />
        <FavoriteAction
          collectId={props.collect?.id}
          variant="link"
          size="icon"
          class="size-6"
          target={props.content}
        />
        <ShareAction
          target={props.content}
          variant="link"
          size="icon"
          class="size-6"
        />
      </div>
    </div>
  );
}
