import { cva } from 'class-variance-authority';
import { For, createSignal, splitProps } from 'solid-js';
import type { ReactionResponseDto } from '~/modules/api';
import { api } from '~/modules/api';
import { cn } from '~/utils';
import { Button } from '../ui';

const ReactionIcons = ['😍', '🔥', '😂', '🤔', '🤯'];

const IconsToKind: Record<string, ReactionResponseDto['kind']> = {
  '😍': 'HeartEyes',
  '🔥': 'Fire',
  '😂': 'LoudlyCryingFace',
  '🤔': 'ThinkingFace',
  '🤯': 'ExplodingHead',
};

export interface ReactionPanelProps {
  hide?: boolean;
  contentType: 'flip' | 'spin';
  contentId: string;
  userId?: string;
  class?: string;
  children?: never;
  initialReactions: Record<ReactionResponseDto['kind'], string>;
}

export function ReactionPanel(props: ReactionPanelProps) {
  const [, rest] = splitProps(props, ['contentType', 'contentId', 'class']);
  // TODO: @svallory convert to icons

  const [reactedWith, setReactedWith] = createSignal(props.initialReactions);

  const onAdd = async (kind: ReactionResponseDto['kind']) => {
    const contentId = {} as Record<string, unknown>;

    if (props.contentType === 'flip') {
      contentId.flipId = props.contentId;
    } else {
      contentId.spinId = props.contentId;
    }

    const response = await api.Reactions.addReaction({
      body: {
        kind,
        ...contentId,
      },
    }).then(r => r.data!);
    setReactedWith({ ...reactedWith(), [response.kind]: response.id });
  };

  const onRemove = async (kind: ReactionResponseDto['kind']) => {
    await api.Reactions.deleteReaction({
      path: {
        reactionId: reactedWith()[kind],
      },
    }).then(r => r.data!);
    setReactedWith({ ...reactedWith(), [kind]: undefined });
  };

  const onReact = async (emoji: string) => {
    const kind = IconsToKind[emoji];
    const reacted = reactedWith()[kind] ?? false;
    if (reacted) {
      return onRemove(kind);
    } else {
      return onAdd(kind);
    }
  };

  if (props.hide) {
    return null;
  }

  return (
    <aside
      {...rest}
      class={cn(
        `flex flex-col py-16 md:py-24 px-4 md:px-6 mx-auto rounded-2xl border
        dark:border-sky-500 bg-white/10 font-display w-full`,
        props.class,
      )}
    >
      <h2
        class="text-lg leading-6 text-center max-w-56 mx-auto mb-2
          dark:text-sky-300"
      >
        {`Did you like this ${props.contentType === 'flip' ? 'FLIP' : 'SPIN'}?\nShare your reaction.`}
      </h2>
      <div
        class="flex flex-wrap gap-2 justify-center content-center mt-5 text-3xl
          font-bold leading-9 whitespace-nowrap"
      >
        <For each={ReactionIcons}>
          {emoji => (
            <ReactionPanelButton
              {...props}
              emoji={emoji}
              onClick={async () => onReact(emoji)}
              variant={
                reactedWith()[IconsToKind[emoji]] != undefined
                  ? 'pressed'
                  : 'unpressed'
              }
            />
          )}
        </For>
      </div>
    </aside>
  );
}

export interface ReactionPanelButtonProps {
  emoji: string;
  contentType: string;
  contentId: string;
  variant: 'pressed' | 'unpressed';
  onClick: () => void;
}

function ReactionPanelButton(props: ReactionPanelButtonProps) {
  const variants = cva(
    'flex justify-center items-center size-14 border dark:border-sky-400 rounded-full text-3xl select-none',
    {
      variants: {
        variant: {
          unpressed: 'bg-white/10 hover:bg-white/20',
          pressed: 'bg-primary hover:bg-gold-300',
        },
      },
      defaultVariants: {
        variant: 'unpressed',
      },
    },
  );

  return (
    <Button
      class={cn(variants({ variant: props.variant }))}
      variant="default"
      tabindex="0"
      onClick={props.onClick}
      aria-label={`React with ${props.emoji}`}
    >
      {props.emoji}
    </Button>
  );
}
