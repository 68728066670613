import { Show, createSignal, type Accessor, type Signal } from 'solid-js';
import { LikesIcon } from '~/components/icons';
import { Button, type ButtonProps } from '~/components/ui';
import {
  ReactionKind,
  type FlipResponseDto,
  type SeriesResponseDto,
  type SpinResponseDto,
} from '~/modules/api';
import { debounce, showAlert } from '~/utils';
import { reactOrUnReact, reportAnalytics } from './reaction.shared';

type Target = SeriesResponseDto | SpinResponseDto | FlipResponseDto;
type ReactionId = string | undefined;

export interface LikeButtonProps extends ButtonProps {
  reactionId: string | undefined;
  target: Target;
}

interface ChildrenProps {
  reactionId: Accessor<ReactionId>;
}

const toggleLike = (
  [reactionId, setReactionId]: Signal<ReactionId>,
  target: Target,
) => {
  const currentState = reactionId();
  reactOrUnReact(currentState, ReactionKind.HEART, target)
    .then(added => {
      if (added) {
        setReactionId(currentState ? undefined : added.id);
        reportAnalytics(target, ReactionKind.HEART);
      } else {
        setReactionId(currentState);
      }
    })
    .catch(() => {
      setReactionId(currentState);
      showAlert(
        `Could not ${currentState ? 'remove reaction' : 'add reaction'}`,
        {
          type: 'error',
        },
      );
    });
};

export function LikeAction(props: LikeButtonProps) {
  const [reactionId, setReactionId] = createSignal<ReactionId>(
    props.reactionId,
  );

  const debouncedToggle = debounce(() => {
    toggleLike([reactionId, setReactionId], props.target);
  });

  return (
    <Button {...props} onClick={debouncedToggle} aria-label="Like">
      {props.size != 'icon' && <FullTextButton reactionId={reactionId} />}
      {props.size == 'icon' && <IconButton reactionId={reactionId} />}
    </Button>
  );
}

function FullTextButton(props: ChildrenProps) {
  return [
    <IconButton {...props} />,
    <Show when={props.reactionId()}>Unlike</Show>,
    <Show when={!props.reactionId()}>Like</Show>,
  ];
}

function IconButton(props: ChildrenProps) {
  return [
    <Show when={props.reactionId()}>
      <LikesIcon class="size-6" fill="#fff" />
    </Show>,
    <Show when={!props.reactionId()}>
      <LikesIcon class="size-6" />
    </Show>,
  ];
}
